import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";
import engLogo from "../../assets/engLogo.png";
import turkeyLogo from "../../assets/turkeyLogo.png";

class Header2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@smilodonsoft.com" +
      "?subject=" +
      encodeURIComponent(mailSubject[this.props.language]) +
      "&body=" +
      encodeURIComponent(mailMessage[this.props.language]));
  }

  render() {
    const { scrolled } = this.state;

    return (
      <>
        <header
          className={
            scrolled
              ? "main-header header-style-two fixed-header"
              : "main-header header-style-two"
          }
        >
          <div className="header-top-two">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left" style={{ alignItems: "center" }}>
                  <ul className="contact-info">
                    <li>
                      <span
                        className="text-dark contactIcon cursorPointer"
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        onClick={() =>
                          this.sendMail({
                            mailSubject: ["Tanışma", "Meeting"],
                            mailMessage: [
                              "Merhaba SmilodonSoft , \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                              "Hello SmilodonSoft \n \n I want to meet with you. \n Please contact me. \n \n Thanks, \n",
                            ],
                          })
                        }
                      >
                        <i
                          className="far fa-envelope-open "
                          style={{ marginRight: "5px", marginLeft: "80px" }}
                        />
                        info@smilodonsoft.com
                      </span>
                    </li>
                    {/* <li >
                                    <a
                        className="text-dark contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905370557311"
                      >
                            <i className="far fa-phone" />
                            +90 537 055 7311
                          </a>
                                    </li> */}
                    <li>
                      <a
                        className="text-dark contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905370557311" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      >
                        <i
                          className="fab fa-whatsapp"
                          style={{ marginLeft: "80px" }}
                        />
                        +90 537 055 7311
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="top-middile">
                  {/* <div className="text" >Şirketimize Hoşgeldiniz</div> */}
                </div>

                <div className="top-right">
                  {/* <div className="language">
                                        <form action="#" className="language-switcher">
                                            <select 
                                            className="selectpicker"
                                            >
                                                <option value="1" >EN </option>
                                                <option value="1" >TR</option>
                                                <option value="1">SP</option>
                                                <option value="1">BE</option>
                                            </select>
                                        </form>
                                    </div> */}
                  <ul className="clearfix social-links">
                    <li
                      className="nav-item zoom "
                      style={{ cursor: "pointer" }}
                    >
                      <span className="nav-link " data-bs-dismiss="offcanvas">
                        {this.props.language === 0 ? (
                          <span
                            onClick={() => this.props.setLanguage(1)}
                            className="zoom "
                          >
                            <img
                              className="rounded navIcon"
                              src={engLogo}
                              alt="english software"
                              width="19"
                              height="19"
                            />
                            &nbsp;EN
                          </span>
                        ) : (
                          <span
                            onClick={() => this.props.setLanguage(0)}
                            className="zoom"
                          >
                            <img
                              className="rounded navIcon"
                              src={turkeyLogo}
                              alt="yazilim"
                              width="19"
                              height="19"
                            />
                            &nbsp;TR
                          </span>
                        )}
                      </span>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/SmilodonSoft"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/SmilodonSoft"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-facebook-square"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/smilodonsoft/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/smilodonsoft/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-linkedin"></span>
                      </a>
                    </li>
                    {/* <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                <div className="nav-outer clearfix">
                  <div
                    className="logo-box"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="logo">
                      <Link to={"/"}>
                        <img
                          src={require("../../assets/images/logo.png")}
                          alt=""
                          style={{ marginTop: "11px" }}
                        />
                      </Link>{" "}
                      <br />
                      {/* <Link to={'/'}><img src={require('../../assets/images/smilodonkafa.png')} alt=""/></Link> <br/>
                                          <Link to={'/'}><img src={require('../../assets/images/l.png')} alt=""/></Link> */}
                    </div>
                  </div>

                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <Menu
                      language={this.props.language}
                      setLanguage={(data) => this.props.setLanguage(data)}
                    />
                  </nav>
                  {/* <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Get A Quote</span></Link></div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to={"/#"} title="">
                  <img src="assets/images/sticky-logo-2.png" alt="" title="" />
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu
                    language={this.props.language}
                    setLanguage={(data) => this.props.setLanguage(data)}
                  />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to={"/#"}>
                  <img src="assets/images/logo.png" alt="" title="" />
                </Link>
              </div>
              <div className="menu-outer">
                <Menu
                  language={this.props.language}
                  setLanguage={(data) => this.props.setLanguage(data)}
                />
              </div>

              <div className="social-links">
                <ul className="clearfix social-links">
                  <li className="nav-item zoom " style={{ cursor: "pointer" }}>
                    <span className="nav-link " data-bs-dismiss="offcanvas">
                      {this.props.language === 0 ? (
                        <span
                          onClick={() => this.props.setLanguage(1)}
                          className="zoom  text-white"
                        >
                          <img
                            className="rounded navIcon"
                            src={engLogo}
                            alt="english software"
                            width="19"
                            height="19"
                          />
                          &nbsp;EN
                        </span>
                      ) : (
                        <span
                          onClick={() => this.props.setLanguage(0)}
                          className="zoom text-white"
                        >
                          <img
                            className="rounded navIcon"
                            src={turkeyLogo}
                            alt="yazilim"
                            width="19"
                            height="19"
                          />
                          &nbsp;TR
                        </span>
                      )}
                    </span>
                  </li>
                  {/* <li><a href='https://twitter.com/SmilodonSoft' target="_blank"><span className="fab fa-twitter"></span></a></li> */}
                  <li>
                    <a
                      href="https://www.facebook.com/SmilodonSoft"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fab fa-facebook-square"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/smilodonsoft/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fab fa-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/smilodonsoft/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fab fa-linkedin"></span>
                    </a>
                  </li>
                  {/* <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li> */}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default Header2;
