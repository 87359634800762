import React, { useEffect, useRef } from "react";
import ac from "../../assets/twIcon.png";

let tvScriptLoadingPromise;
const TvDemo = () => {
  const onLoadScriptRef = useRef();
  useEffect(() => {
    onLoadScriptRef.current = createWidget;
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;
        document.head.appendChild(script);
      });
    }
    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );
    return () => (onLoadScriptRef.current = null);
    function createWidget() {
      if (
        document.getElementById("tradingview_fff") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          autosize: true,
          symbol: "NASDAQ:MSFT",
          interval: "D",
          timezone: "Europe/Istanbul",
          theme: "dark",
          style: "2",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_fff",
          withdateranges: true,
          hide_side_toolbar: false,
          details: true,
          hotlist: true,
          calendar: true,
          show_popup_button: true,
          popup_width: 1500,
          popup_height: 800,
          watchlist: ["NASDAQ:AAPL", "NASDAQ:MSFT"],
        });
      }
    }
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "black", height: "500" }}></div>
      <div className="container-fluid">
        <div className="card shadow">
          <div className="card-header">
            <div className="tradingview-widget-container">
              <div
                id="tradingview_fff"
                style={{ height: "calc(100vh - 80px)" }}
              />
            </div>
            <div
              className=" text-center mt-2"
              style={{
                left: "20%",
                color: "black",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a
                href="https://tr.tradingview.com/"
                target="_blank"
                rel="dofollow"
              >
                <img
                  className="rounded navIcon mx-2"
                  src={ac}
                  alt="tradingview"
                  width="30"
                  height="30"
                />
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    color: "black",
                  }}
                >
                  TradingView
                </span>
              </a>
              <span style={{ marginTop: "10px" }}>
                TradingView, dünya çapında tüccarlar ve yatırımcıların bir araya
                geldiği, piyasaları analiz ettiği ve finansal haberleri takip
                ettiği bir grafik platformudur. Gelişmiş araçları ve çeşitli
                verileri sayesinde kullanıcılar, ilgilendikleri herhangi bir
                varlığı inceleyerek piyasa araştırmalarını daha derinlemesine
                yapabilirler. Özellikle işlem tutkunları, TradingView'in sunduğu
                özelliklerden memnuniyet duyacaktır. Platform, detaylı sembol
                grafikleriyle birlikte
                <a
                  href="https://www.tradingview.com/economic-calendar/"
                  target="_blank"
                  rel="dofollow"
                  className="mx-1"
                >
                  ekonomi takvimi
                </a>
                grafikleri gibi kapsamlı verilere de erişim sağlar.
                Kullanıcıların stratejilerine uygun daha geniş veriler sunarak
                yatırım kararlarını desteklemeye yardımcı olur. Aynı zamanda
                hisse senedi yatırımcıları için de faydalı göstergeler sunar.
                Örneğin, {/* <span style={{ color: "black" }}> */}
                <a
                  href="https://www.tradingview.com/screener/"
                  target="_blank"
                  rel="dofollow"
                  className="mr-1"
                >
                  Hisse Senedi Tarayıcısı
                </a>
                {/* </span> */}
                sayesinde kullanıcılar hisse senedi piyasalarını gözden
                geçirebilir ve uygun varlıkları portföyleri için kolayca
                bulabilirler.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TvDemo;
