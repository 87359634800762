import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/Header2'
import Footer from '../layout/footer'
import ServiceFilter1 from '../element/service-filter1'
import { services2 } from '../layout/language';

const mobile = require('./../../assets/images/mobile.png');
const vector = require('./../../assets/images/vector.png');
const web = require('./../../assets/images/web-design.png');
const domain = require('./../../assets/images/domain.png');
const tv = require('./../../assets/images/twIcon.png');
class Services2 extends Component {


    render() {
        return (
            <>
                 <Header language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
                 {/* <!--Search Popup--> */}
                {/* <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div> */}
                
                {/* <!-- Page Banner Section --> */}
                {/* <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Our Services</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">Services</li>
                        </ul>
                    </div>

                </section> */}
                {/* <!--End Banner Section --> */}


                {/* <!-- Services Section Five --> */}
                <section className="services-section-five" style={{marginTop:"17%"}}>
                    <div className="auto-container">
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="nav-tabs tab-btn-style-one">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <div className="icon-box">
                                        <div className="icon"><span 
                                            // className="flaticon-team"
                                            >
                                                <img src={mobile} alt='mobile' width='80' height='80'></img>
                                                </span>
                                            </div>
                                            <h5>{services2.s1[this.props.language]}</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <div className="icon-box">
                                        <div className="icon"><span 
                                            // className="flaticon-money"
                                            ><img src={vector} alt='mobile' width='80' height='80'></img>
                                                </span></div>
                                            <h5>{services2.s2[this.props.language]}</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        <div className="icon-box">
                                        <div className="icon"><span 
                                            // className="flaticon-assets"
                                            ><img src={web} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>{services2.s3[this.props.language]}</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">
                                        <div className="icon-box">
                                        <div className="icon"><span 
                                            // className="flaticon-world"
                                            ><img src={domain} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>{services2.s4[this.props.language]}</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">
                                    <div className="icon-box">
                                    <div className="icon"><span 
                                            // className="flaticon-money-1"
                                            ><img src={tv} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>TradingView</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="sixth">
                                        <div className="icon-box">
                                            <div className="icon"><span className="flaticon-notebook"></span></div>
                                            <h5>Criminal Case</h5>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image "><img className='rounded' src={require('../../assets/images/resource/uygulama-removebg-preview.png')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">{services2.s1[this.props.language]}</div>
                                                    <h2>{services2.s5[this.props.language]}<br/>{services2.s6[this.props.language]}</h2>
                                                </div>
                                                <div className="text">{services2.s7[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/contact'} className="theme-btn btn-style-one"><span className="btn-title">{services2.s10[this.props.language]}</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img className='rounded' src={require('../../assets/images/resource/graphic.jpg')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">{services2.s2[this.props.language]}</div>
                                                    <h2>{services2.s8[this.props.language]}<br/></h2>
                                                </div>
                                                <div className="text">{services2.s9[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/contact'} className="theme-btn btn-style-one"><span className="btn-title">{services2.s10[this.props.language]}</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img className='rounded' src={require('../../assets/images/resource/web.jpg')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">{services2.s3[this.props.language]}</div>
                                                    <h2>{services2.s11[this.props.language]}<br/></h2>
                                                </div>
                                                <div className="text"><h4>{services2.s12[this.props.language]}</h4><br/>{services2.s13[this.props.language]} </div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">{services2.s10[this.props.language]}</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/www.jpg')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">{services2.s4[this.props.language]}</div>
                                                    <h2>{services2.s15[this.props.language]} <br/>{services2.s16[this.props.language]}</h2>
                                                </div>
                                                <div className="text">{services2.s17[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/contact'} className="theme-btn btn-style-one"><span className="btn-title">{services2.s10[this.props.language]}</span></Link></div>
                                            </div>                                
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img className='rounded' src={require('../../assets/images/resource/tv.jpg')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">TradingView</div>
                                                    <h2>{services2.s18[this.props.language]} <br/></h2>
                                                </div>
                                                <div className="text"><h3>{services2.s19[this.props.language]} </h3><br/> {services2.s20[this.props.language]} </div>
                                                <div className="link-btn"><Link to={'/contact'} className="theme-btn btn-style-one"><span className="btn-title">{services2.s10[this.props.language]}</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt=""/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title">
                                                    <div className="sub-title">Get Rewards</div>
                                                    <h2>Just Proved Our Selves <br/>For Great Works.</h2>
                                                </div>
                                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>

                {/* <!-- Gallery Section --> */}
                <ServiceFilter1 language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)}/>

                {/* <!-- Sponsors Section --> */}

                {/* <Brand1/> */}




                 
                <Footer language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
            </>
        )
    }
}
export default Services2;