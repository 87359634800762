import React, { useState } from "react";
import { port1 } from "../layout/language";

const images = [
    {
        id: 13,
        name: "VillaCepte",
        business: true,
        imgUrl: require('../../assets/images/gallery/z.png'),
        title:'https://villacepte.com/'
    },
    {
        id: 8,
        name: "Seyfettin Uslu",
        family: true,
        imgUrl: require('../../assets/images/gallery/h.png'),
        title:'https://seyfettinuslu.com/#'
    },
    {
        id: 9,
        name: "Must Danışmanlık ",
        family: true,
        imgUrl: require('../../assets/images/gallery/i.png'),
        title:'https://www.mustdanismanlik.com/'
    },
    {
        id: 10,
        name: "Minel Terapi",
        family: true,
        imgUrl: require('../../assets/images/gallery/j.png'),
        title:'https://www.minelterapi.com/'
    },
    {
        id: 10,
        name: "AhazGroup",
        family: true,
        imgUrl: require('../../assets/images/gallery/y.png'),
        title:'https://ahazgroup.com/'
    },
    {
        id: 11,
        name: "Menadiyet",
        business: true,
        imgUrl: require('../../assets/images/gallery/k.png'),
        title:'https://www.menadiyet.com/'
    },
    {
        id: 1,
        name: "Harita Mühendisleri İş Adamları Derneği",
        banking: true,
        imgUrl: require('../../assets/images/gallery/a.png'),
        title:'https://harmiad.com/'
    },
    {
        id: 2,
        name: "Çağ Akademi",
        banking: true,
        imgUrl: require('../../assets/images/gallery/b.png'),
        title:'https://www.cagakademi.com/'
    },
    {
        id: 3,
        name: "Ruomed Medikal",
        insurance: true,
        imgUrl: require('../../assets/images/gallery/c.png'),
        title:'https://www.ruomed.com/'
    },
    {
        id: 4,
        name: "Atamer Ağız ve Diş Sağlığı Polikliniği",
        insurance: true,
        imgUrl: require('../../assets/images/gallery/d.png'),
        title:'https://www.atamerdis.com/'
    },
    {
        id: 5,
        name: "Rüzgar Havacılık",
        insurance: true,
        imgUrl: require('../../assets/images/gallery/e.png'),
        title:'https://www.ruzgarhavacilik.com/'
    },
    {
        id: 6,
        name: "Altıntepe Madencilik",
        insurance: true,
        imgUrl: require('../../assets/images/gallery/l.png'),
        title:'https://altintepemaden.com.tr/'
    },
    {
        id: 7,
        name: "Fight Club Turkey",
        family: true,
        imgUrl: require('../../assets/images/gallery/g.png'),
        title:'https://fightclubturkey.com/'
    },
    {
        id: 12,
        name: "Evliyaoğlu Maden",
        business: true,
        imgUrl: require('../../assets/images/gallery/f.png'),
        title:'https://evliyaoglumaden.com/'
    }
];

const FILTER_DEFS = {
    BANKING: image => image.banking,
    INSURANCE: image => image.insurance,
    FAMILY: image => image.family,
    BUSINESS: image => image.business,
    NONE: image => image
};

const PortfolioFilter1 = ({props,language}) => {
    const [state, setState] = useState({
        list: images,
        filterKey: "NONE"
    });

    const { list, filterKey } = state;
    const filteredList = list.filter(FILTER_DEFS[filterKey]);
    return (
        <>
            <section className="gallery-section" style={{marginTop:"15%"}}>
                <div className="sortable-masonry">
                    <div className="auto-container">
                        <div className="row m-0 justify-content-md-between align-items-center">
                            <div className="sec-title ">
                                <div className="sub-title ">{port1.p1[language]}</div>
                                <h2>{port1.p2[language]}</h2>
                            </div>
                            {/* <!--Filter--> */}
                            <div className="filters text-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <ul className="filter-tabs filter-btns">
                                    {/* <li className="active filter" onClick={() => setState({ ...state, filterKey: "NONE" })}><span>Financial</span></li> */}
                                    {/* <li className="filter" onClick={() => setState({ ...state, filterKey: "BANKING" })}><span>Banking</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "INSURANCE" })}><span>Insurance </span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "FAMILY" })}><span>Family</span></li>
                                    <li className="filter" onClick={() => setState({ ...state, filterKey: "BUSINESS" })}><span>Business</span></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="items-container row">

                            {filteredList.map(image => (
                                <div className="col-lg-3 col-md-6 gallery-block-one" key={image.id}>
                                    <div className="inner-box">
                                        <a href={image.title} target='_blank' rel="noopener noreferrer">
                                        <div className="image">
                                            <img src={image.imgUrl} alt="" />
                                        </div>
                                        <div className="caption-title">
                                            <h5>{image.name}</h5>
                                            {/* <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></Link></div> */}
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
















        </>
    );
};

export default PortfolioFilter1;
