import React, { useState } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import Index2 from "./pages/index-2";
import About from "./pages/about";
import Contact from "./pages/contact";
import Portfolio1 from "./pages/portfolio-1";
import Services2 from "./pages/services-2";
import BackToTop from "./layout/backToTop";
import Tv from "./pages/Tv";

function Router() {
  const [language, setLanguage] = useState(0);

  return (
    <HashRouter hashType={"noslash"}>
      {/* <BrowserRouter> */}
      <div className="page-wrapper">
        <Switch>
          <Route path="/" exact>
            <Index2
              language={language}
              setLanguage={(data) => setLanguage(data)}
            />
          </Route>
          <Route path="/about" exact>
            <About
              language={language}
              setLanguage={(data) => setLanguage(data)}
            />
          </Route>
          <Route path="/services-2" exact>
            <Services2
              language={language}
              setLanguage={(data) => setLanguage(data)}
            />
          </Route>
          <Route path="/portfolio-1" exact>
            <Portfolio1
              language={language}
              setLanguage={(data) => setLanguage(data)}
            />
          </Route>
          <Route path="/contact" exact>
            <Contact
              language={language}
              setLanguage={(data) => setLanguage(data)}
            />
          </Route>
          <Route path="/tv" exact>
            <Tv language={language} setLanguage={(data) => setLanguage(data)} />
          </Route>
        </Switch>

        <BackToTop />
      </div>
    </HashRouter>
  );
}

export default Router;
