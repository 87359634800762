import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { footer } from './language';

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="main-footer">
                    <div className="auto-container">

                        <div className="widgets-section">
                            <div className="row clearfix">


                                <div className="column col-lg-9 " style={{marginTop:"10%"}}>
                                    <div className="footer-widget logo-widget">
                                        <div className="widget-content">
                                            <div className="footer-logo">
                                                {/* <Link to="index.html"><img className="" src={require('../../assets/images/flogo.png')} alt="" /></Link> */}
                                                <Link to={'/'}><img className='mx-2' src={require('../../assets/smilodonkafa.png')} alt=""/></Link>
                                          <Link to={'/'}><img src={require('../../assets/l.png')} alt=""/></Link>
                                            </div>
                                            <div className="text">{footer.t1[this.props.language]} </div> 
                                            <ul className="social-links clearfix">
                                            <li><a href='https://twitter.com/SmilodonSoft' target="_blank" rel="noopener noreferrer"><span className="fab fa-twitter"></span></a></li>
                                            <li><a href='https://www.facebook.com/SmilodonSoft' target="_blank" rel="noopener noreferrer"><span className="fab fa-facebook-f"></span></a></li>
                                            <li><a href='https://www.instagram.com/smilodonsoft/' target="_blank" rel="noopener noreferrer"><span className="fab fa-instagram"></span></a></li>
                                            <li><a href='https://www.linkedin.com/company/smilodonsoft/' target="_blank" rel="noopener noreferrer"><span className="fab fa-linkedin"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className="column col-lg-3">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-content">
                                            <h3>{footer.link[this.props.language]}</h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li><Link to={'/'}>{footer.link1[this.props.language]}</Link></li>
                                                        <li><Link to={'/about'}>{footer.link2[this.props.language]}</Link></li>
                                                        <li><Link to={'/services-2'}>{footer.link3[this.props.language]}</Link></li>
                                                        <li><Link to={'/portfolio-1'}>{footer.link4[this.props.language]}</Link></li>
                                                        <li><Link to={'/tv'}>TradingView</Link></li>
                                                        <li><Link to={'/contact'}>{footer.link5[this.props.language]}</Link></li>
                                                    </ul>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <ul>
                                                        <li><Link to={'/#'}>News</Link></li>
                                                        <li><Link to={'/#'}>Press Release</Link></li>
                                                        <li><Link to={'/#'}>Case Study</Link></li>
                                                        <li><Link to={'/#'}>Terms</Link></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="column col-lg-4">
                                    <div className="footer-widget instagram-widget">
                                        <h3>Photo Showcase</h3>
                                        <div className="inner-box">
                                            <div className="wrapper-box">
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-1.jpg')} alt="" />
                                                    
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-1.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-2.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-2.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-3.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-3.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-4.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-4.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-5.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-5.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-6.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-6.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-7.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-7.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-8.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-8.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-9.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-9.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>

                    <div className="auto-container">
                        <div className="footer-bottom">
                            <div className="copyright">Copyright By<Link to={'/'}>SmilodonSoft</Link> - 2023</div>
                        </div>
                    </div>
                </footer>


            </>
        );
    }
}

export default Footer;