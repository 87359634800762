import React, { Component } from 'react'
import Footer from '../layout/footer'
import Header2 from '../layout/Header2'
import TvDemo from './TvDemo'

export default class tv extends Component {
  render() {
    return (
      <>
      <Header2 language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
      <div className='tv-section'><TvDemo/></div>
      
      <Footer language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
      </>
    )
  }
}
