// 0 Turkish , 1 English

export const navbar = {
    nav1:["Anasayfa","Home"],
    nav2:["Hakkımızda","About Us"],
    nav3:["Hizmetler","Services"],
    nav4:["Referanslar","References"],
    nav5:["İletişim","Contact"],
}
export const footer = {
    link:["Linkler","Links"],
    link1:["Anasayfa","Home"],
    link2:["Hakkımızda","About Us"],
    link3:["Hizmetler","Services"],
    link4:["Referanslar","References"],
    link5:["İletişim","Contact"],
    t1:["Gelişmiş ve alanında uzman ekip arkadaşlarımız ile siz müşterilerimize en güzel hizmetleri sunuyoruz.İnternet sitesi ve uygulama tasarımlarının yanı sıra farklı alanlarda yardımcı olabilmekteyiz. Sosyal Medya Hesaplarımızı Aşağıda Bulabilirsiniz...",
        "We provide the best services to our clients with with our teammates who expert on their areas.As well as Website and Application designs we can help in other areas. You can find our social media links below...   "],
}

export const index2 = {
  i1:["Uygulama","Application"],
  i2:["Grafik","Graphic"],
  i3:["İnternet Sitesi","Website"],
  i4:["TradingView","TradingView"],
  i5:["Alan Adı / Sunucu","Hosting / Server"],
  i6:["Müşteri Memnuniyeti","Customer Happiness"],
  i7:["Yapılan İşler","Finished Work"],
  i8:["Çözülen Problemler","Solved Problems"],
  i9:["İletişim","Contact"],
  i10:["Bize Buradan Ulaşınız","Contact Us Here"],
  i11:["İsminizi Buraya Giriniz.....","Enter Name Here....."],
  i12:["Mail Adresinizi Buraya Giriniz.....","Enter Email Here....."],
  i13:["Mesajınızı Buraya Yazınız.....","Enter Message Here....."],
  i14:["Gönder","Send"],
  i15:["Hizmetlerimiz","Our Services"],
  i16:["Biz Aslında Ne Hizmeti Veriyoruz","What Actually We Do Here"],
  i17:["Web Sitesi ve Uygulama","Website and Application"],
  i18:["İnternet Sitesi (Şirket / E-ticaret / Ürün), Uygulama (Masaüstü / Mobil) ve Grafik Tasarımı (İnternet Sitesi / Uygulama) Hizmetleri Sunuyoruz.",
  "We Provide Website (Company / E-Trade / Product),Application(Desktop / Mobile) and Graphic Design (Website / Application)."],
  i19:["Alan Adı / Sunucu","Hosting / Server"],
  i20:["Destek Hizmeti (Web Servis Sağlayıcıları / Sunucu / Bulut) Sunuyoruz.","We provide support service(Web Service Providers / Server / Cloud )"],
  i21:["Dünya Piyasasını Canlı ve Güncel Olarak Hizmetinize Sunuyoruz.","We offer the world market as live and update"],
  i22:["Hizmetler","Services"],
  i23:["Uygulama Hizmetler","Application Services"],
  i24:["Masaüstü / Mobil","Desktop / Mobile"],
  i25:["İnternet Sitesi","Web Site"],
  i26:["Şirket / E-ticaret / Ürün","Company / E-Commerce / Product "],
  i27:["Grafik Dizayn","Graphic Design"],
  i28:["Mükemmel Görsellik","Excellent Visual"],
  i29:["Destek Hizmeti ","Support Service"],
  i30:[" Güncel Dünya Borsası ","Current World Stock Exchange"],
}

export const testimonial1 = {
    t1:["Referanslar","References"],
    t2:["Müşteri Geri Bildirimi","Client Feedback"],
}

export const about = {
    a1:["Hakkımızda","About Us"],
    a2:["Teknoloji ve","Technology and"],
    a3:["Yazılım Şirketi","Software Company"],
    a4:["Büyük hedefler ile çıktığımız bu yolda her geçen gün hedeflerimizi büyüterek devam etmekteyiz.Uzman ve alanında en iyisi olan kadromuz ile  internet sitesi,masaüstü ve mobil uygulamalarının yanısıra web servis sağlayıcıları,sunucu ve bulut hizmetleride vermekteyiz.",
        "We continue on this road, which we set out with our big goals, by increasing our goals day by day.We provide web service providers, server and cloud services as well as website, desktop and mobile applications with our expert and best staff. "],
    a5:["Tarihçe","History"],
    a6:["Seyahatimiz","Our Journey"],
    a7:["Ekibimiz","Our Team"],
    a8:["Lider Takımı","Leadership Team"],
    a9:["Kurucu","Founder"],
    a10:["Özellikler","Features"],
    a11:["Temel Özellikler","Core Features"],
    a12:["Kaliteli Hizmet","Quality Service"],
    a13:["Sunduğumuz tüm hizmetleri 1.sınıf,en güzel dizaynlar ve en kaliteli şekilde teslim ediyoruz.",
         "We deliver all the services we offer in first class, the most beautiful designs and the highest quality."],
    a14:["Hızlı Geri Bildirim","Fast Feedback"],
    a15:["İş öncesinde,yapım aşamasında ve sonrasında siz değerli müşterilerimize en hızlı şekilde dönüş yapıyoruz. ",
         "Before, during the work and after, we return to our valued customers as quickly as possible."],
    a16:["Güvenilirlik","Reliability"],
    a17:["İşlerimizi anlaşma şartlarına uygun olarak,siz müşterilerimizin istediği şekilde söz verdiğimiz süre içerisinde teslim ediyoruz.",
         "We deliver our works in accordance with the terms of the agreement, as requested by our customers, within the time we promised."],
    a18:["Alanında Uzmanlık","Expertise in the Field"],
    a19:["Alanında uzman kadromuz ile en kaliteli,en güncel,en şık projeleri siz değerli müşterilerimize sunuyoruz.",
         "We offer the highest quality, the most up-to-date and stylish projects to our valued customers with our expert staff."],
    a20:["Daha Fazlası İçin","Learn More"],
    a21:["Turkish","English"],
    a22:["Turkish","English"],
    a23:["Turkish","English"],
    a24:["Turkish","English"],
    a25:["Turkish","English"],
    a26:["Turkish","English"],
    a27:["Turkish","English"],
    a28:["Turkish","English"],
    a29:["Turkish","English"],
    a30:["Turkish","English"],
    a31:["Turkish","English"],
    a32:["Turkish","English"],
}

export const services2 = {
    s1:["Uygulama","Application"],
    s2:["Grafik Tasarım","Graphic Design"],
    s3:["İnternet Sitesi","Web Site"],
    s4:["Alan Adı / Sunucu","Hosting / Server"],
    s5:["Uygulama Hizmeti","Application Service"],
    s6:["Masaüstü / Mobil","Desktop / Mobile"],
    s7:["Kullanıcıların istedikleri işlemleri yapmalarını sağlayan yazılımlara uygulama denir. Uygulamalar, günümüzde birden fazla platformda çalışacak şekilde oluşturulmaktadır. Uygulamaların geliştirilme aşamasında, bazı yazılım dillerine ek olarak bazı uygulama geliştirme araçları da kullanılır. Uygulamaların genel amacı insan yaşamını kolaylaştırmaktır. Uygulamalar bir işletim sisteminde çalışabileceği gibi internet siteleri, cep telefonları ve tabletlerde de çalışabilir ve kullanılabilir.",
        "Software that allows users to do whatever they want is called an application.Applications are built to run on multiple platforms today.During the development of applications, some application development tools are used in addition to some software languages.The general purpose of the applications is to facilitate human life.Applications can run on an operating system as well as on websites, mobile phones and tablets."],
    s8:["İnternet Sitesi / Uygulama","Web Site / Application"],
    s9:["Grafik tasarım bir mesajı iletmek, bir görseli geliştirmek veya bir düşünceyi görselleştirmek için metnin ve görsellerin algılanabilir ve görülebilir bir düzlemde, iki boyutlu veya üç boyutlu olarak organize edilmesini içeren yaratıcı bir süreçtir.Birçok ortamda dijital veya dijital olmayacak bir şekilde uygulanabilir. Grafik tasarımında ve grafik sanatlarda, görsel sanatların temel ilkeleri olan hizalama, denge, karşıtlık, vurgulama, hareket, görüntü, oran, yakınlık, tekrarlama, ritim, ve birlik geçerlidir.",
        "Graphic design is a creative process that involves organizing text and images on a perceptible and visible plane, two-dimensional or three-dimensional, in order to convey a message, enhance an image, or visualize a thought. It can be applied in many environments, digital or non-digital.In graphic design and graphic arts, the basic principles of visual arts such as alignment, balance, contrast, emphasis, movement, image, proportion, proximity, repetition, rhythm, and unity apply."],
    s10:["Daha Fazlası İçin","Learn More"],
    s11:["Şirket / E-ticaret / Ürün","Compant / E-Commerce / Product"],
    s12:["Neden İnternet Siteniz Olmalı ?","Why Do You Should Have a Website ?"],
    s13:["Profesyonel bir web sitesi müşterinizi artırır. Ürün veya hizmet satın alırken, web sitesi olan işletmeleri, olmayan işletmelere tercih edeceğini belirten tüketicilerin oranı %70. Öncelikle sadece iş yerinin önünden geçenlerle yetinmemek gerekiyor. Onun için işinizi bir web sitesi ile internete taşımak birinci adım. Daha sonra insanlar bir ürün veya hizmet aradığında karşılarına çıkmalısınız. Nasıl mı? İnternet sitesi olan firmalar hem doğal yollarla hem de reklamlarla ürün veya hizmet arayan müşterilerin karşısına çıkıyor.  Ama her site müşteri kazandırmaz. Biz bunun için çalışıyoruz.",
         "A professional website increases your customer.While purchasing products or services, 70% of consumers state that they would prefer businesses that have a website to businesses that do not.First of all, you should not be content with only those who pass in front of the workplace.That's why moving your business to the internet with a website is the first step.Then when people search for a product or service, you should come across them.How Does ? Companies with websites come across customers who are looking for products or services both naturally and through advertisements.But not every site gains customers. We are working for it."],
    s14:["Neden İnternet Siteniz Olmalı ?","Why Do You Should Have a Website ?"],
    s15:["Destek Hizmeti","Support Service"],
    s16:["Sunucu / Web Servis Sağlayıcıları / Bulut","Server / Web Service Providers / Cloud"],
    s17:["Alan adı, bir web sitesinin internet üzerindeki adı ve adresidir.Hosting, bir web sitesinin internet üzerinde yayınlanmasını sağlamak için kullanılan bir hizmettir. Web sitesi, bir bilgisayarın diskinde saklanan bir dosya dizisidir ve bu dosyalar internete yüklenerek, dünya çapında herhangi bir bilgisayardan erişilebilir hale getirilir. Bu işlem, hosting hizmetleri aracılığıyla gerçekleştirilir.",
         "A domain name is the name and address of a website on the internet.Hosting is a service used to enable a website to be published on the internet.A website is a set of files stored on a computer's disk and these files are uploaded to the internet, making it accessible from any computer worldwide.This is done through hosting services."],
    s18:["Borsa Grafiği","Stock Chart"],
    s19:["TradingView nedir?","What is the TradingView ?"],
    s20:["Dünya çapında 30 milyondan fazla işlem yapan birey ve yatırımcı tarafından küresel piyasalardaki fırsatları tespit etmek için kullanılan bir grafik platformu ve sosyal ağdır.",
         "It is a charting platform and social network used by more than 30 million trading individuals and investors worldwide to spot opportunities in global markets."],
    s21:["Hizmetler","Services"],
    s22:["Neler","What"],
    s23:["Yapıyoruz","We Do"],
    s24:["Hepsi","All"],
}

export const port1 = {
    p1:["Portföy","Portfolio"],
    p2:["İşlerimiz","Our Works"],
}

export const contact = {
    c1:["Mail Adresi","Email Address"],
    c2:["Telefon Numarası","Phone Number"],
    c3:["Ofis Adresi","Office Address"],
    c4:["İletişim","Contact"],
    c5:["Lütfen Bizimle İletişime Geçin","Please Contact Us"],
    c6:["İsminizi Giriniz","Enter Your Name"],
    c7:["Lütfen İsminizi Buraya Giriniz.....","Please Enter Your Name Here....."],
    c8:["Mail Adresinizi  Giriniz","Enter Your Email Address"],
    c9:["Lütfen Mail Adresinizi Buraya Giriniz.....","Please Enter Your Email Address Here....."],
    c10:["Mesajınızı Yazınız","Write Your Message"],
    c11:["Lütfen Mesajınızı Buraya Yazınız.....","Please Write Your Message Here..... "],
    c12:["Gönder","Send"],
}