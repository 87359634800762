import React, { Component,} from 'react';
import { Link } from 'react-router-dom';
import Header2 from '../layout/Header2';
import HomeSlider2 from '../element/home-slider2';
import Footer from '../layout/footer';
import Testimonial1 from '../element/testimonial1'
import '../../assets/css/color-2.css';
import { index2 } from '../layout/language';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const counterbg1 = require('./../../assets/images/background/image-5.jpg');
const touchbg1 = require('./../../assets/images/background/image-8.jpg');
const mobile = require('./../../assets/images/mobile.png');
const vector = require('./../../assets/images/vector.png');
const web = require('./../../assets/images/web-design.png');
const domain = require('./../../assets/images/domain.png');
const tv = require('./../../assets/images/twIcon.png');



class Index2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: "",
          email: "",
          phone: "",
          message: "",
          alert: "",
        };
      }

      handlePost(e) {
        e.preventDefault();
        if (this.state.name && this.state.email && this.state.message) {
          this.setState({
            alert: ["Mesajınız gönderiliyor, lütfen bekleyiniz...","Your message is being sent, please wait..."],
        });
          try {
            const url = "https://api.smilodonsoft.com/api/Mail";
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
              }),
            };
            return fetch(url, options).then(() => {
              this.setState({ alert:  ["Mesajınız gönderildi, teşekkür ederiz!","Your message was sent, thank you!"] });
            });
          } catch (error) {
            return this.setState({
              alert:
              ["Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
              "Your message could not be sent, please try again or try other contact way in left side "],            });
          }
        } else {
          return this.setState({
            alert:  ["Lütfen gerekli tüm alanları doldurunuz!!!","Please fill all mandatory fields!!!"],
          });
        }
      }

    render() {
        
        return (
            <>
                <Header2 language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />


                {/* <!--Search Popup--> */}
                {/* <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div> */}

                <HomeSlider2  language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />


                {/* <!-- Feature Section --> */}
                <section className="feature-section" >
                    <div className="auto-container" >
                        <div className="wrapper-box">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span 
                                            // className="flaticon-team"
                                            >
                                                <img src={mobile} alt='mobile' width='80' height='80'></img>
                                                </span>
                                            </div>
                                            <h5>{index2.i1[this.props.language]}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span 
                                            // className="flaticon-money"
                                            ><img src={vector} alt='mobile' width='80' height='80'></img>
                                                </span></div>
                                            <h5>{index2.i2[this.props.language]}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span 
                                            // className="flaticon-assets"
                                            ><img src={web} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>{index2.i3[this.props.language]}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span 
                                            // className="flaticon-world"
                                            ><img src={domain} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>{index2.i5[this.props.language]}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span 
                                            // className="flaticon-money-1"
                                            ><img src={tv} alt='mobile' width='80' height='80'></img></span></div>
                                            <h5>{index2.i4[this.props.language]}</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div className="feature-block-one">
                                        <div className="inner-box">
                                            <div className="icon"><span className="flaticon-notebook"></span></div>
                                            <h5>Criminal Case</h5>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- About Section Two --> */}
                {/* <section className="about-section-two">
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="sec-title">
                                        <div className="sub-title">About Us</div>
                                        <h2>We Work With <br />You To Address</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                                    </div>
                                    <div className="author-info">
                                        <div className="wrapper-box">
                                            <h2>Rosalina D. William</h2>
                                            <div className="designation">Founder</div>
                                            <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod..</div>
                                        </div>
                                        <div className="signature"><img src={require('../../assets/images/resource/signature-2.png')} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="image-one">
                                        <img src={require('../../assets/images/resource/image-10.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


                {/* <!-- Funfacts Section --> */}
                <section className="funfacts-section" style={{ backgroundImage: "url(" + counterbg1 + ")" }}>
                    <div className="auto-container">
                        {/* <!-- Fact Counter --> */}
                        <div className="fact-counter-two">
                            <div className="row">
                                {/* <!--Column--> */}
                                <div className="column counter-column col-xl-4 col-lg-6">
                                    <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="content">
                                            <div className="icon"><img src={require('../../assets/images/icons/satisfaction.png')} alt="" /></div>
                                            <div className="count-outer count-box">
                                                <CountUp
                                                    end={98}
                                                    redraw={true}
                                                >
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor
                                                            onChange={start}
                                                            delayedCall
                                                        >
                                                            <span className="count-text" ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                                <span>%</span>
                                            </div>
                                            <div className="counter-title">{index2.i6[this.props.language]}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--Column--> */}
                                <div className="column counter-column col-xl-4 col-lg-6">
                                    <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="content">
                                            <div className="icon"><img src={require('../../assets/images/icons/team.png')} alt="" /></div>
                                            <div className="count-outer count-box">
                                            <span style={{fontSize:"80px"}}>+</span>
                                                <CountUp
                                                    end={+100}
                                                    redraw={true}
                                                >
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor
                                                            onChange={start}
                                                            delayedCall
                                                        >
                                                            <span className="count-text" ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                                
                                            </div>
                                            <div className="counter-title">{index2.i7[this.props.language]}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--Column--> */}
                                <div className="column counter-column col-xl-4 col-lg-6 ">
                                    <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                        <div className="content">
                                            <div className="icon"><img src={require('../../assets/images/icons/solved.png')} alt="" /></div>
                                            <div className="count-outer count-box">
                                            <span style={{fontSize:"80px"}}>+</span>

                                                <CountUp
                                                    end={2000}
                                                    redraw={true}
                                                >
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor
                                                            onChange={start}
                                                            delayedCall
                                                        >
                                                            <span className="count-text" ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                            </div>
                                            <div className="counter-title">{index2.i8[this.props.language]}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--Column--> */}
                                {/* <div className="column counter-column col-xl-3 col-lg-6">
                                    <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                        <div className="content">
                                            <div className="icon"><img src={require('../../assets/images/icons/icon-15.png')} alt="" /></div>
                                            <div className="count-outer count-box">
                                                <CountUp
                                                    end={93}
                                                    redraw={true}
                                                >
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor
                                                            onChange={start}
                                                            delayedCall
                                                        >
                                                            <span className="count-text" ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}
                                                </CountUp>
                                                <span>%</span>
                                            </div>
                                            <div className="counter-title">Business Monitoring</div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Statistic Section --> */}
                {/* <section className="statistic-section">
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="graph"><img src={require('../../assets/images/resource/graph.png')} alt="" /></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-title">
                                    <div className="sub-title">Our Startegy</div>
                                    <h2>Get Done With <br /> Us Your Project</h2>
                                </div>
                                <div className="progress-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <h5>Business Planing</h5>
                                    <div className="bar">
                                        <div data-percent="74%"><div className="count-text">74%</div></div>
                                        <ProgressBar  className="count-bar" now={74} />
                                    </div>
                                </div>
                                <div className="progress-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <h5>Business Planing</h5>
                                    <div className="bar">
                                    <ProgressBar  className="count-bar" now={64} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <!-- Services Section Two --> */}
                <section className="services-section-two">
                    <div className="auto-container">
                        <div className="wrapper-box">
                            <div className="left-column" style={{ backgroundImage: "url(" + touchbg1 + ")" }}>
                                <div className="sec-title light">
                                    <div className="sub-title">{index2.i9[this.props.language]}</div>
                                    <h2>{index2.i10[this.props.language]} <br /> </h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div className="contact-form">
                                    <form method="post" action="http://azim.commonsupport.com/Finandox/sendemail.php" id="contact-form">
                                        <div className="row clearfix">
                                            <div className="col-md-12 form-group">
                                                <input type="text" name="username" placeholder={index2.i11[this.props.language]} required=""
                                               id='name' onChange={(e) => {
                                                this.setState({ name: e.target.value });
                                                this.setState({ alert: "" });
                                              }} />
                                                <i className="fas fa-user"></i>
                                            </div>

                                            <div className="col-md-12 form-group">
                                                <input type="email" name="email" placeholder={index2.i12[this.props.language]} required=""
                                                id='email'  onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                    this.setState({ alert: "" });
                                                  }} />
                                                <i className="fas fa-envelope"></i>
                                            </div>

                                            <div className="col-md-12 form-group">
                                                <textarea name="message" placeholder={index2.i13[this.props.language]}
                                                id='message' onChange={(e) => {
                                                    this.setState({ message: e.target.value });
                                                    this.setState({ alert: "" });
                                                  }}></textarea>
                                                <i className="fas fa-edit"></i>
                                            </div>

                                            <div className="col-md-12 form-group">
                                                <button className="theme-btn btn-style-one" type="submit" name="submit-form" onClick={(e) => this.handlePost(e)}><span className="btn-title">{index2.i14[this.props.language]}</span></button>
                                            </div>
                                        </div>
                                        {this.state.alert && (
                    <div>
                      <div className="form-message-warning mt-4" />
                      <div className="form-message-success text-white">{this.state.alert[this.props.language]}</div>
                    </div>
                  )}
                                    </form>
                                </div>
                            </div>
                            <div className="right-column">
                                <div className="services-content">
                                    <div className="sec-title">
                                        <div className="sub-title">{index2.i15[this.props.language]}</div>
                                        <h2>{index2.i16[this.props.language]}<br /></h2>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/coding.png')} alt="" /></div>
                                        <h2>{index2.i17[this.props.language]}</h2>
                                        <div className="text">{index2.i18[this.props.language]} </div>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/domain.png')} alt="" /></div>
                                        <h2>{index2.i19[this.props.language]}</h2>
                                        <div className="text">{index2.i20[this.props.language]} </div>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/twIcon.png')} alt="" /></div>
                                        <h2>TradingView</h2>
                                        <div className="text">{index2.i21[this.props.language]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Gallery Section --> */}
                <section className="gallery-section">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">{index2.i22[this.props.language]}</div>
                            {/* <h2>Sunduğumuz Hizmetler</h2> */}
                        </div>
                        <div className="row">
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/1.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5>{index2.i23[this.props.language]}</h5>
                                        <h3>{index2.i24[this.props.language]}</h3>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                            <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/2.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5>{index2.i25[this.props.language]}</h5>
                                        <h3>
                                        {index2.i26[this.props.language]}
                                                </h3>
                                    </div>
                                                </Link>
                                </div>
                            </div>
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                            <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/8.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5>{index2.i27[this.props.language]}</h5>
                                        <h3>
                                        {index2.i28[this.props.language]}
                                                </h3>
                                    </div>
                                                </Link>
                                </div>
                            </div>
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                            <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/7.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5>{index2.i19[this.props.language]}</h5>
                                        <h3>
                                        {index2.i29[this.props.language]}
                                                </h3>
                                    </div>
                                                </Link>
                                </div>
                            </div>
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                            <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/5.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5>TradingView</h5>
                                        <h3>
                                        {index2.i30[this.props.language]}
                                                </h3>
                                    </div>
                                                </Link>
                                </div>
                            </div>
                            {/* <!-- Gallery Block One --> */}
                            <div className="col-lg-4 col-md-6 style-two gallery-block-one">
                                <div className="inner-box">
                                            <Link to={'/services-2'}>
                                    <div className="image">
                                        <img src={require('../../assets/images/gallery/6.jpg')} alt="" />
                                    </div>
                                    <div className="caption-title">
                                        <h5> {index2.i19[this.props.language]}</h5>
                                        <h3>
                                        {index2.i29[this.props.language]}
                                                </h3>
                                    </div>
                                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Video Section --> */}

                {/* <VideoPopup2 /> */}
                {/* <!-- Team Section --> */}
                {/* <section className="team-section"> */}
                    {/* <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">Our Team</div>
                            <h2>Leadership Team</h2>
                        </div> */}
                        {/* <div className="row">
                            // {/* <!-- Team Block One --> */}
                            {/* // <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"> */}
                            {/* //     <div className="inner-box"> */}
                            {/* //         <div className="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt="" /></div> */}
                            {/* //         <div className="content"> */}
                            {/* //             <div className="designation">Founder</div> */}
                            {/* //             <h3>Rosalina D. William</h3> */}
                            {/* //             <ul className="social-links"> */}
                            {/* //                 <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li> */}
                            {/* //                 <li><Link to={'/#'}><span className="fab fa-twitter"></span></Link></li> */}
                            {/* //                 <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li> */}
                            {/* //                 <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li> */}
                            {/* //             </ul> */}
                            {/* //         </div> */}
                            {/* //     </div> */}
                            {/* </div> */} 
                            {/* <!-- Team Block One --> */}
                            {/* <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                    <div className="content">
                                        <div className="designation">CEO</div>
                                        <h3>Velavos H. DesuJa</h3>
                                        <ul className="social-links">
                                            <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-twitter"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- Team Block One --> */}
                            {/* <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt="" /></div>
                                    <div className="content">
                                        <div className="designation">Developer</div>
                                        <h3>Abdur Rahman J.</h3>
                                        <ul className="social-links">
                                            <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-twitter"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </section> */}

                {/* <!-- Testimonial Section --> */}
                <Testimonial1 language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)}/>

                {/* <!-- News Section --> */}
                {/* <section className="news-section">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">News</div>
                            <h2>News From Resource</h2>
                        </div>
                        <div className="row">
                            {/* <!-- News Block One --> */}
                            {/* <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} data-src="assets/images/resource/news-1.jpg" alt="" /></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br />business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- News Block One --> */}
                            {/* <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} data-src="assets/images/resource/news-2.jpg" alt="" /></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>Business consultant is involved in the planning.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- News Block One --> */}
                            {/* <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt="" /></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>Business consultant is a professional who analyzes.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </section> */} 





                <Footer language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
            </>
        )
    }
}
export default Index2;