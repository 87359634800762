import React, { Component } from 'react';
import Header from '../layout/Header2';
import Footer from '../layout/footer';
import { about } from '../layout/language';




class About extends Component {


    render() {
        return (
            <>
                <Header language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
                {/* <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div> */}


                {/* <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>About Us</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">About Us</li>
                        </ul>
                    </div>

                </section> */}


                {/* About Section Two */}
                <section className="about-section-two " style={{marginTop:"7%"}}>
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-wrapper">
                                    <div className="image-one">
                                        <img className='rounded' src={require('../../assets/images/resource/aaa.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <div className="sub-title">{about.a1[this.props.language]}</div>
                                        <h2>{about.a2[this.props.language]} <br />{about.a3[this.props.language]}</h2>
                                        <div className="text">{about.a4[this.props.language]} </div>
                                    </div>
                                    {/* <div className="author-info">
                                        <div className="wrapper-box">
                                            <h2>Rosalina D. William</h2>
                                            <div className="designation">Founder</div>
                                            <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod..</div>
                                        </div>
                                        <div className="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/* History Section */}
                {/* <section className="history-section">
                    <div className="auto-container">
                        <div className="sec-title text-center light">
                            <div className="sub-title">{about.a5[this.props.language]}</div>
                            <h2>{about.a6[this.props.language]}</h2>
                        </div>
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="nav-tabs tab-btn-style-one">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">2022</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">2021</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">2020</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">2019</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">2018</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title light">
                                                    <div className="sub-title">{about.a21[this.props.language]}</div>
                                                    <h2>{about.a22[this.props.language]} <br />{about.a23[this.props.language]}</h2>
                                                </div>
                                                <div className="text">{about.a24[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">{about.a20[this.props.language]}</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title light">
                                                    <div className="sub-title">{about.a25[this.props.language]}</div>
                                                    <h2>{about.a26[this.props.language]} <br />{about.a27[this.props.language]}</h2>
                                                </div>
                                                <div className="text">{about.a28[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">{about.a20[this.props.language]}</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title light">
                                                    <div className="sub-title">{about.a29[this.props.language]}</div>
                                                    <h2>{about.a30[this.props.language]} <br />{about.a31[this.props.language]}</h2>
                                                </div>
                                                <div className="text">{about.a32[this.props.language]}</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">{about.a20[this.props.language]}</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title light">
                                                    <div className="sub-title">Get Rewards</div>
                                                    <h2>Just Proved Our Selves <br />For Great Works.</h2>
                                                </div>
                                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image"><img src={require('../../assets/images/resource/image-18.jpg')} alt="" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content pl-lg-4">
                                                <div className="sec-title light">
                                                    <div className="sub-title">Get Rewards</div>
                                                    <h2>Just Proved Our Selves <br />For Great Works.</h2>
                                                </div>
                                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</div>
                                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Learn More</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section> */}

                {/* Team Section */}
                {/* <section className="team-section">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">{about.a7[this.props.language]}</div>
                            <h2>{about.a8[this.props.language]}</h2>
                        </div>
                        <div className="row"> */}
                            {/* Team Block One */}
                            {/* <div className="col-lg-4 team-block-one">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt="" /></div>
                                    <div className="content">
                                        <div className="designation">{about.a9[this.props.language]}</div>
                                        <h3>İlyas Koçak</h3>
                                        <ul className="social-links">
                                            <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><a href='https://www.linkedin.com/in/ilyaskocak/' target="_blank"><span className="fab fa-linkedin"></span></a></li>
                                            <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* Team Block One */}
                            {/* <div className="col-lg-4 team-block-one">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                    <div className="content">
                                        <div className="designation">{about.a9[this.props.language]}</div>
                                        <h3>Aykut Uyar</h3>
                                        <ul className="social-links">
                                            <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><a href='https://www.linkedin.com/in/uyaraykut/' target="_blank"><span className="fab fa-linkedin"></span></a></li>
                                            <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* Team Block One */}
                            {/* <div className="col-lg-4 team-block-one">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt="" /></div>
                                    <div className="content">
                                        <div className="designation">{about.a9[this.props.language]}</div>
                                        <h3>Sedat Karataş</h3>
                                        <ul className="social-links">
                                            <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><a href='https://www.linkedin.com/in/sedat-karatas-17778a235/' target="_blank"><span className="fab fa-linkedin"></span></a></li>
                                            <li><Link to={'/#'}><span className="fab fa-behance"></span></Link></li>
                                            <li><Link to={'/#'}><span className="fab fa-youtube"></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        {/* </div>
                    </div>
                </section> */}

                {/* Services Section Four */}
                <section className="services-section-four services-section-about">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">{about.a10[this.props.language]}</div>
                            <h2>{about.a11[this.props.language]}</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/guarantee.png')} alt="" /></div>
                                    <h2>{about.a12[this.props.language]}</h2>
                                    <div className="text">{about.a13[this.props.language]} </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/conversation.png')} alt="" /></div>
                                    <h2>{about.a14[this.props.language]} </h2>
                                    <div className="text">{about.a15[this.props.language]}</div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/trust.png')} alt="" /></div>
                                    <h2>{about.a16[this.props.language]}</h2><br/>
                                    <div className="text">{about.a17[this.props.language]} </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/expert.png')} alt="" /></div>
                                    <h2>{about.a18[this.props.language]}</h2>
                                    <div className="text">{about.a19[this.props.language]} </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Financial Strategy</h2>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="icon-box">
                                    <div className="icon"><img src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Financial Planning</h2>
                                    <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                {/* Sponsors Section */}


                {/* <Brand1 /> */}






                <Footer language={this.props.language} setLanguage={(data)=>this.props.setLanguage(data)} />
            </>
        )
    }
}
export default About;